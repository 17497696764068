@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";
@import "~@/styles/mixins";



























































































































































































 @mixin mq_modal_landscape() {
  @media (orientation: landscape) and (max-width: $device_xs),
    (orientation: landscape) and (max-height: $device_s) {
      @content;
    }
 }

.welcome-modal {

  .modal {
    border-radius: 16px;
    padding: 0 0 24px 0;

    @include mq_modal_landscape {
      padding: 0;
      border-radius: 0;
    }
  }

  .modal__close {
    display: none;
  }

  .modal__body {
    height: 100%;
  }

  .modal__footer {

    @include mq-l {
      background: white;
    }

    @include mq_modal_landscape {
      left: unset;
      width: 65%;
      right: 0;
      box-shadow: none;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 856px;
    text-align: center;

    @include mq-xs {
      min-height: 496px
    }

    @include mq_modal_landscape {
      flex-direction: row;
      text-align: left;
    }
  }

  &__illustration {
    display: flex;
    height: 344px;
    background-color: #0954AD;

    @include mq-s {
      height: 220px;
    }

    @include mq_modal_landscape {
      width: 35%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__carousel {
    @include mq_modal_landscape {
      width: 65%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 42px 24px 0 24px;

    @include mq-xs {
      padding: 16px;
    }

    @include mq_modal_landscape {
      padding: 24px;
      width: 100%;
      align-items: start;
    }

    .info__title {
      margin-bottom: 16px;

      @include mq-s {
        font-size: 20px;
        width: 232px;
      }
    }

    .info__description {
      max-width: 560px;
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    padding: 0 32px;

    @include mq-m {
      justify-content: space-between;
      padding: 0 8px;
    }

    @include mq_modal_landscape {
      justify-content: space-between;
      padding: 0 8px;
    }

    .actions__left {
      width: fit-content;
    }

    .actions__right {
      display: flex;
      justify-content: center;
      width: 70%;

      @include mq-m {
        justify-content: right;
        width: auto;
      }

      @include mq_modal_landscape {
        justify-content: right;
      }
    }
  }

  .VueCarousel-dot {
    margin-top: 0 !important;

    &--active {
      background-color: $color-primary !important;
    }

    &-container {
      @include mq-xs {
        margin-top: 0 !important;
      }

      @include mq_modal_landscape {
        margin-top: 0 !important
      }
    }

    &:focus {
      outline: unset;
    }
  }
}
